import { Controller } from '@hotwired/stimulus'
import Typed from 'typed.js'

export default (class extends Controller {
  static values = { strings: Array }

  connect() {
    this.typed = new Typed(this.element, {
      backSpeed: 15,
      backDelay: 3000,
      loop: true,
      strings: this.stringsValue,
      typeSpeed: 30
    })
  }
})
